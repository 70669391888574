.carousels-container {
  background-color: #021A49;
  padding: 20px;
  color: white;
  overflow: hidden;
  position: relative;
  margin:"auto";
  display: flex;
  flex-direction: row;
}

.carousel-heading {
  font-size: 3em;
  margin-bottom: 20px;
  font-weight: bold;
  color: white;
  width: 20%;
  text-align: center;
  margin-left: 30px;
}
.slider-container{
  width: 70%
}
.carousel-slide {
  text-align: center;
  background-color: white;
  border-radius: 8px;
  max-width: 17rem;
  padding: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: 23rem;
}

.carousel-slide:last-child {
  margin-right: 20px;
}

.carousel-image {
  width: 100%;
  height: 13rem;
  border-radius: 8px;
}

.carousel-title {
  margin-top: 10px;
  font-size: 1rem;
  font-weight: bold;
  color: #041344;
}

.carousel-description {
  font-size: 1rem;
  font-weight: 400;
  color: #667085;
}

.read-more-btn {
  background-color: transparent;
  border: none;
  color: #000000;
  cursor: pointer;
  font-size: 0.9rem;
  margin-top: 10px;
  text-decoration: underline;
}

.read-more-btn:hover {
  color: #0642b9;
}
.custom-arrow {
  background-color: rgb(206, 206, 206); /* Gray background */
  color: #000000; /* Black arrow color */
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.custom-arrow:hover {
  background-color: rgba(0, 0, 0, 0.7); /* Darker background on hover */
  color: #fff; /* White color for arrow on hover */
  transform: translateY(-50%) scale(1.1); /* Slightly larger on hover */
}

.custom-arrow.next {
  right: 1rem;
}

.custom-arrow.prev {
  left: -1rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .carousels-container {
    flex-direction: column;
  }
  .carousel-heading {
    font-size: 3em;
    margin-bottom: 20px;
    font-weight: bold;
    color: white;
    width: 60%;
    text-align: center;
    margin-left: 30px;
  }

  .custom-arrow.next {
    right: -1rem;
  }

  .custom-arrow.prev {
    left: -1rem;
  }
}

@media (max-width: 468px) {
  .carousels-container {
    flex-direction: column;
  }

  .custom-arrow {
    width: 30px;
    height: 30px;
    font-size: 16px;
  }

  .custom-arrow.next {
    right: -1rem;
  }

  .custom-arrow.prev {
    left: -1rem;
  }
}

