.carousel-container {
    padding: 0.5rem;
    border-radius: 10px;
  }
  
  .carousel-slider {
    margin-top: 1rem;
  }
  
  .carousel-item {
    text-align: center;
  }
  
  .carousel-navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 1rem;
  }
  
  .carousel-title {
    color: #000;
    margin: 0;
    font-size: 1.25rem;
  }
  
  .carousel-description {
    color: #666;
    margin: 0.5rem 0;
    font-size: 0.875rem;
  }
  
  .carousel-logo {
    width: auto;
    height: 350px;
    margin: 1rem 2rem;
  }
  
  .nav-icon {
    cursor: pointer;
    margin: 0 10rem;
    z-index: 999;
    background-color: #D9D9D9;
    border-radius: 30px;
  }
  
  .carousel-container .slick-dots li button:before {
    margin: 5.5rem 0rem;
    justify-content: center;
    color: #2391D0;
  }
  
  .slick-dots {
    position: absolute;
    bottom: 20px; /* Adjust as needed */
    width: 100%;
    text-align: center;
  }
  
  .slick-dots li {
    display: inline-block;
    margin: 0 5px; /* Adjust spacing between dots */
  }
  
  /* Media Queries for Responsiveness */
  
  @media (max-width: 1200px) {
    .nav-icon {
      margin: 0 5rem;
    }
    
    .carousel-logo {
      height: 150px;
      margin: 1rem;
    }
  }
  
  @media (max-width: 992px) {
    .carousel-title {
      font-size: 1rem;
    }
    
    .carousel-description {
      font-size: 0.75rem;
    }
    
    .carousel-logo {
      height: 120px;
    }
    
    .nav-icon {
      margin: 0 3rem;
    }
  }
  
  @media (max-width: 768px) {
    .carousel-container {
      padding: 0.5rem;
      box-shadow: none;
    }
    
    .carousel-title {
      font-size: 0.875rem;
    }
    
    .carousel-description {
      font-size: 0.75rem;
    }
    
    .carousel-logo {
      height: 100px;
    }
    
    .nav-icon {
      margin: 0 2rem;
    }
  }
  
  @media (max-width: 576px) {
    .carousel-container {
      padding: 0.5rem;
      box-shadow: none;
    }
    
    .carousel-title {
      font-size: 0.75rem;
    }
    
    .carousel-description {
      font-size: 0.625rem;
    }
    
    .carousel-logo {
      height: 80px;
    }
    
    .nav-icon {
      margin: 0 1rem;
    }
    
    .slick-dots li button:before {
      font-size: 0.625rem;
    }
  }
  