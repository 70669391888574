.App {
  text-align: center;
} 
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.background-light{
  background-color: #ffffff !important;
}
.header_text_down{
  position: absolute;
}
.background-blue{
  background-color: #63769a !important;
}
.background-lightblue{
  background-color: #EFF0F8 !important;
}
.background-orange{
  background-color: #F15215 !important;
}
.height-110{
  height: 110px !important;
}
.w-500{
  width: 50%;
}
.disabled{
  cursor: not-allowed !important;
}
.background-button{
 background-color: rgb(20,24,75) !important;
}

.flexBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 20px -1px, rgba(0, 0, 0, 0.06) 0px 5px 6px -1px;
  padding: 10px;
}

.pb-20{
  padding-bottom: 20px;
}

.pb-10{
  padding-bottom: 10px;
}

.pb-8{
  padding-bottom: 8px;
}
.heading_bold{
  font-weight: 600 !important;
}
.body1_color{
  color:#667085;
}
.objectFit{
  object-fit: cover;
}
.achivemets_data{
  color: #6840c6;
  background-color: #6840c613;
  border-radius: 10px;
  font-size: 12px;
}
.bule_color{
  color: #192F59;
}
.background-dark-blue{
  background-color: #002858;
}
.fs-10{
  font-size: 10px;
}
.fs-20{
  font-size: 20px;
}
.fs-15{
  font-size: 15px;
}
.fs-14{
  font-size: 14px;
}
.text-justify{
  text-align: justify;
}
.mt-150 {
  margin-top: 150px;
}
.mt-125 {
  margin-top: 120px;
}

.multine-ellipsis {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
.multine-ellipsis-4 {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
.multine-ellipsis-2 {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
.cursor-pointer{
  cursor: pointer;
}

.cursor-pointer-disable{
  cursor: not-allowed !important;
}
.vertical_text{
  writing-mode: vertical-lr;
  color: #fff !important;
}
.vertical_text:first-child{
writing-mode: horizontal-tb;
}
.table_header{
  background-color: #002858;
  color: #fff !important;
  width:30px;
}
.hide_description{
  height: 80px;
  overflow: hidden;
}
.text-decoration{
  text-decoration: underline;
}

/* .navRoutes.active {
  text-decoration: underline;
  color: red;
} */
.activeLink {
  color: red; /* Set the color to red for the active link */
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.Register_btn {
  border-width: 3px;
  border-color: rgb(255, 255, 0);
  animation: blink 1s infinite;
}
