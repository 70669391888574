.radio-cell {
  padding: 10px;
  text-align: center;
  border-left: 1px solid #e2e2e2;
}

.answer-divider {
  position: absolute;
  height: 50%;
  width: 1px;
  right: 0;
  top: 0;
  border: 1px solid rgb(0, 0, 0);
}

.feedBack_container {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  flex-wrap: nowrap !important;
}

.submission-message {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.submission-message Button {
  background-color: #63769A;
  font-weight: 600;
  color: white;
}

.submission-message Button:hover {
  background-color: #808080;
}

.row-layout {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 20rem;
}

.column-layout {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.form-group {
  flex: 2;
}

.column-layout {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
.row-layout {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
}
.custom-dropdown {
  width: 100%;
}


@media screen and (max-width: 768px) {
  .feedBack_container {
    flex-direction: column;
    color: rgb(85, 106, 193);
  }
}

.feedback-table-container {
  margin: 20px 0;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 42rem !important;
}

.table-header {
  background-color: #032269;
  border-bottom: 2px solid #1976d2;
  color: white;
}

.vertical-text {
  font-weight: bold;
  text-transform: capitalize;
  text-align: center;
  padding: 10px;
  color: white !important;
  border-left: 1px solid white;
}

.table-row {
  transition: background-color 0.2s;
}

.table-row:hover {
  background-color: #f0f0f0;
}

.question-cell {
  font-weight: 500;
  padding: 12px;
}

.custom-dropdown {
  width: 100%;
  padding: 10px;
  border-top: none;
  border-left: none;
  border-right: none;
  font-size: 15px;
  background-color: #fff;
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path fill="black" d="M10 12.5l-5-5h10z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px;
}

.custom-dropdown:focus {
  outline: none;
}
/* Ignor */

.form-group {
  margin-bottom: 1.5rem;
}

.form-label {
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.form-control {
  width: 100%;
  padding: 0.375rem 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-dropdown {
  appearance: none;
  background-color: #f8f9fa;
}

.custom-dropdown:focus {
  border-color: #80bdff;
}

textarea.form-control {
  resize: none;
}
