.Course_Header{
max-height:auto;
background-color:#36348E;
border-radius:8px
}
.course_offering_heading{
    color: #fff;
    width: 125px;
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    border-bottom: 1px solid #F15215;
}
.course_offering_list{
    list-style-type: none;
    padding: 0;
}
.course_offering_list li {
    font-size: 12px;
    font-weight: 500;
    color:#fff;
}